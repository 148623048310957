// src/components/Home/HomeLawyers.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import fetchGraphQLData from '../../apiCalls/fetchGraphQLData';
import LawyerCard from '../../components/LawyerCard';
import './HomeLawyers.css';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { FaArrowRight } from "react-icons/fa";



gsap.registerPlugin(ScrollTrigger);

function HomeLawyers() {
  const [lawyers, setLawyers] = useState([]);
  const lawyerCardsRef = useRef([]);
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/dikigoroi');
  };

  useEffect(() => {
    const fetchLawyers = async () => {
      const query = `
      {
        lawyers(first: 6) {
          id
          firstName
          lastName
          slugName
          lawGrade
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawProfileImage {
            url
          }
        }
      }
      `;
      const data = await fetchGraphQLData(query);
      if (data && data.lawyers) {
        setLawyers(data.lawyers); // Update state with fetched lawyers
      }
    };

    fetchLawyers();
  }, []);

  // Set up GSAP timeline when lawyer cards are rendered
  useEffect(() => {
    if (lawyers.length > 0) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: '.lawyer-cards-container', // Class or element that triggers the animation
          start: 'top 80%', // Start animation when the container comes 80% into the viewport
          toggleActions: 'play none none none', // Play the animation once
        },
      });

      // Animate each card one after another
      timeline.from(lawyerCardsRef.current, {
        opacity: 1,
        y: 15,
        stagger: 0.3, // Delay between each card's animation
        duration: 1, // Duration of each card's animation
        ease: 'power3.out',
      });
    }
  }, [lawyers]);

  return (
    <Container className="mt-4 pt-4 lawyer-cards-container">
      <h1 className="text-center mb-2">Δικηγόροι</h1>
      <p className="hero-p text-center mb-4"> Κορυφαίοι δικηγόροι με ειδίκευση στο ποινικό, αστικό και εμπορικό δίκαιο.</p>
      <Row className="g-4">
        {lawyers.length > 0 ? (
          lawyers.map((lawyer, index) => (
            <Col
              key={lawyer.id}
              xs={12}
              sm={6}
              md={4}
              className="mb-4"
              ref={(el) => (lawyerCardsRef.current[index] = el)}
            >
              <LawyerCard lawyer={lawyer} />
            </Col>
          ))
        ) : (
          <p>Loading...</p> // Handle loading state
        )}
        <Row className="mt-4 pt-4">
            <Col md={12} className="text-center">
            <Button className="btn btn-outline-register" onClick={handleButtonClick}>
                Εμφάνιση όλων <FaArrowRight className="my-1 ms-2" />
            </Button>
            </Col>
        </Row>
      </Row>
    </Container>
  );
}

export default HomeLawyers;