import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

/* Import generic UI components of the upLawyer application */
import NavBarUI from './components/ui-navbar';
import FooterUI from './components/ui-footer';
import CookieUI from './components/ui-cookies';

/* Import lawyer pages of the upLawyer application */
import HomePage from './pages/Home/Home';
import LawyersAll from './pages/AllLawyers/LawyersAll';
import LawyerDetail from './pages/DetailLawyer/LawyerDetail';
import LawAssociationsAll from './pages/AllAssocations/LawAssociationsAll';
import LawAssociationDetail from './pages/LawAssociationDetail';
import BlogPostPage from './pages/LawBlogPost';
import LawBlog from './pages/LawBlog';
import AssistantAI from './pages/AIAssistant/AssistantAI';
import LawSpecialtyAll from './pages/DetailSpecialties/LawSpecialtyAll'

import PolicyPrivacy from './pages/TermsPolicies/PolicyPrivacy';
import PolicyTerms from './pages/TermsPolicies/PolicyTerms'

/* Import generic pages of the upLawyer application */
import Contact from './pages/Contact/Contact';

/* Import auth pages of the upLawyer application */
import AuthSignIn from './components-auth/AuthSignIn';
import AuthSignUp from './components-auth/AuthSignUp';
import UserProfile from './pages/UserProfile';

/* Import restricted routes of the upLawyer application */
import ProtectedRoute from './components/ProtectedRoute';
import PublicRoute from './components/PublicRoute';

/* Import Google Analytics 4 library for React */
import ReactGA from 'react-ga4';

function App() {

  useEffect(() => {
    ReactGA.initialize('G-541JBVP7JY');
    ReactGA.send('pageview');
  }, []);

  return (
    <Router>
      <div className="App d-flex flex-column min-vh-100">
        <NavBarUI />
        <div className="mt-4">
          <Routes>

            <Route path="/" element={<HomePage />} />
            <Route path="/dikigorikoi-syllogoi" element={<LawAssociationsAll />} />
            <Route path="/dikigorikoi-syllogoi/:associationSlug" element={<LawAssociationDetail />} />
            <Route path="/dikigoroi" element={<LawyersAll />} />
            <Route path="/dikigoros/:slugName" element={<LawyerDetail />} />
            <Route path="/eidikotita/:slugname" element={<LawSpecialtyAll />} />
            <Route path="/blog" element={<LawBlog />} />
            <Route path="/blog/:blogSlug" element={<BlogPostPage />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/ai-assistant" element={<AssistantAI />} />

            <Route path="/policy-privacy" element={<PolicyPrivacy />} />
            <Route path="/policy-terms/" element={<PolicyTerms />} />
            
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <AuthSignIn />
                </PublicRoute>
              }
            />

            <Route
              path="/register"
              element={
                <PublicRoute>
                  <AuthSignUp />
                </PublicRoute>
              }
            />
            
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <UserProfile />
                </ProtectedRoute>
              }
            />

          </Routes>
          <CookieUI />
        </div>
        <FooterUI />
      </div>
    </Router>
  );
}

export default App;
