import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";

/* Import UI components from react-bootstrap library */
import { Container, Row, Col } from "react-bootstrap";

/* Import 'court.png' and 'grid.png' images for the section of HomeHero */
import lawyerImage from "../../images/court.png";
// import gridImage from "../../images/grid4.png";

/* Import HeroSection.css for styling the section of HomeHero */
import "./HomeHero.css";

function HomeHero() {
  const heroPRef = useRef(null);
  const heroH1Ref = useRef(null);
  const heroImageRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth < 1068;

    if (!isMobile) {
      const timeline = gsap.timeline({ defaults: { ease: "power3.out" } });

      timeline.fromTo(
        heroH1Ref.current,
        { x: '-10%', opacity: 0 },
        { x: '0%', opacity: 1, duration: 1.2 }
      );

      timeline.fromTo(
        heroPRef.current,
        { opacity: 0, y: '10%' },
        { opacity: 1, y: '0%', duration: 1.5 },
        "-=0.8"
      );

      timeline.fromTo(
        heroImageRef.current,
        { scale: 0.8, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.5 },
        "-=1.2"
      );
    }
  }, []);

  return (
    <div className="hero-section mt-4">
      <div className="hero-background"></div>
      <Container fluid className="py-5 px-5 position-relative">
        <Row className="p-3 p-md-5">
          <Col
            xs={12}
            md={5}
            className="d-flex flex-column justify-content-center align-items-center align-items-md-start"
          >
            <div>
              <h1 ref={heroH1Ref} className="pb-2 text-center text-md-start">
                Αναζήτηση δικηγόρου
              </h1>
              <p
                ref={heroPRef}
                className="py-4 text-center text-md-start hero-p"
              >
                Το <span className="navbar-brand fw-medium">upLawyer.gr</span>{" "}
                προσφέρει μια απλή, σύγχρονη και έξυπνη εμπειρία αναζήτησης δικηγόρων και
                νομικών υπηρεσιών στην Ελλάδα.
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            md={7}
            className="hero-image"
            ref={heroImageRef}
            style={{
              backgroundImage: `url(${lawyerImage})`,
            }}
          ></Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeHero;
