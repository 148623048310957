import React from 'react';
import Card from 'react-bootstrap/Card';
import { FaArrowRight } from 'react-icons/fa';

const BlogPost = ({ title, date, author, excerpt }) => {
  return (
    <Card className="card-blog-post mb-4">
      <Card.Body>
        <div className="d-flex justify-content-between align-items-center">
          <span className="fw-medium">{title}</span>
          <FaArrowRight className="text-black cursor-pointer mx-3" />
        </div>
        <div className="text-md fw-light mt-2">
          <span>{new Date(date).toLocaleDateString()}</span> {/* Display the date */}
        </div>
        <div className="text-md fw-light mt-2">
          <span>{author}</span> {/* Display the author */}
        </div>
        <div className="mt-3">
          <Card.Text>{excerpt}...</Card.Text> {/* Display a preview of the body */}
        </div>
      </Card.Body>
    </Card>
  );
};

export default BlogPost;
