import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { gsap } from "gsap";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button"

import { FaRegEnvelopeOpen } from "react-icons/fa";

import fetchGraphQLData from "../../apiCalls/fetchGraphQLData";
import LawyerCard from "../../components/LawyerCard";

/* Import UI generic components  */
import LoadingSpinner from "../../components/ui-spinner";

/* Import UI components from lawyer-components  */
import LawyerBiography from "./LawyerBio";
import LawyerEducation from "./LawyerEdu";
import LawyerLanguages from "./LawyerLang";
import LawyerBlogPosts from "./LawyerBlog";
import LawyerContact from "./LawyerContact";

/* Import react-helmet library for SEO optimization  */
import { Helmet } from "react-helmet";

import "./LawyerDetail.css";

const languageToCountryCode = {
  english: "GB",
  french: "FR",
  german: "DE",
  spanish: "ES",
};

function LawyerDetailPage() {
  const { slugName } = useParams();
  const [lawyer, setLawyer] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const lawyerCardRef = useRef(null);
  const bioRef = useRef(null);
  const eduRef = useRef(null);
  const langRef = useRef(null);
  const blogRef = useRef(null);

  useEffect(() => {
    const fetchLawyerDetails = async () => {
      const query = `
      {
        lawyers(where: { slugName: "${slugName}" }) {
          id
          firstName
          lastName
          slugName
          lawSpecialty {
            specialtyName
          }
          lawAssociation {
            associationName
            associationSlug
          }
          lawAddress {
            lawStreet
            lawCity
            lawRegion
            lawZipCode
          }
          lawContact {
            lawPhone
            lawPhoneType
          }
          lawGrade
          lawProfileImage {
            url
          }
          lawBlogPost {
            lawPostTitle
            lawPostBody {
              markdown
            }
            lawPostSlug
          }
          lawSchoolUnder {
            lawSchoolName
          }
          lawSchoolPost {
            lawSchoolName
            lawSchoolTitle
          }
          lawLanguage
          lawShortBio
          lawEmail
        }
      }
      `;
      const data = await fetchGraphQLData(query);

      if (data && data.lawyers.length > 0) {
        setLawyer(data.lawyers[0]);
      }
    };

    fetchLawyerDetails();
  }, [slugName]);

  // GSAP animation setup
  useEffect(() => {
    const isMobile = window.innerWidth < 1068;

    // Only run animations if not on mobile
    if (lawyer && !isMobile) {
      const timeline = gsap.timeline({ defaults: { ease: "power2.out" } });

      timeline.fromTo(
        lawyerCardRef.current,
        { opacity: 0, y: "-20%" },
        { opacity: 1, y: "0%", duration: 1 }
      );

      timeline.fromTo(
        bioRef.current,
        { opacity: 0, y: "10%" },
        { opacity: 1, y: "0%", duration: 1 },
        "-=0.8"
      );

      timeline.fromTo(
        eduRef.current,
        { opacity: 0, y: "10%" },
        { opacity: 1, y: "0%", duration: 1.2 },
        "-=0.8"
      );

      timeline.fromTo(
        langRef.current,
        { opacity: 0, y: "10%" },
        { opacity: 1, y: "0%", duration: 1.2 },
        "-=0.8"
      );

      timeline.fromTo(
        blogRef.current,
        { opacity: 0, y: "10%" },
        { opacity: 1, y: "0%", duration: 1.2 },
        "-=0.8"
      );
    }
  }, [lawyer]);

  if (!lawyer) {
    return <LoadingSpinner />;
  }

  const gradeClass = lawyer.lawGrade
    ? `lawyer-card-grade-${lawyer.lawGrade.toLowerCase()}`
    : "";

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <Helmet>
        <title>{`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr - Βρές δικηγόρο και άλλες νομικές υπηρεσίες στην Ελλάδα.`}</title>
        <meta
          name="description"
          content={`${lawyer.firstName} ${lawyer.lastName} - Δικηγόρος με ειδίκευση σε ${lawyer.lawSpecialty
            .map((s) => s.specialtyName)
            .join(", ")}.`}
        />
        <meta property="og:title" content={`${lawyer.firstName} ${lawyer.lastName} - upLawyer.gr`} />
        <meta property="og:description" content={`${lawyer.firstName} ${lawyer.lastName} - Δικηγόρος με ειδίκευση σε ${lawyer.lawSpecialty.map((s) => s.specialtyName).join(", ")}.`} />
        <meta property="og:image" content={lawyer.lawProfileImage.url} />
        <meta property="og:url" content={`https://uplawyer.gr/dikigoros/${slugName}`} />
      </Helmet>
  
      <Container className={`lawyer-details-container ${window.innerWidth >= 1068 ? "pt-5" : ""} my-4 ${showModal ? "blur-background" : ""}`}>
        <Row>
          {/* Column for LawyerCard */}
          <Col md={4}>
            <div ref={lawyerCardRef}>
              <LawyerCard lawyer={lawyer} />
            </div>
            {/* Full-width Contact Button */}
            <div className="mt-4">
              <Button className="btn btn-primary-contact w-100" onClick={handleShowModal}>
                Στείλε μήνυμα <FaRegEnvelopeOpen className="ms-2" />
              </Button>
            </div>
          </Col>
  
          {/* Column for Biography, Education, and Languages */}
          <Col md={7} className="ms-md-5">
            <div ref={bioRef}>
              <LawyerBiography
                lawSchoolUnder={lawyer.lawSchoolUnder}
                lawShortBio={lawyer.lawShortBio}
              />
            </div>
            <div ref={eduRef}>
              <LawyerEducation
                lawSchoolUnder={lawyer.lawSchoolUnder}
                lawSchoolPost={lawyer.lawSchoolPost}
              />
            </div>
  
            {/* Languages section (no flexbox classes) */}
            <div ref={langRef}>
              <LawyerLanguages
                lawLanguage={lawyer.lawLanguage}
                languageToCountryCode={languageToCountryCode}
              />
            </div>
          </Col>
        </Row>
  
        {/* Row for Blog Posts */}
        <Row>
          <Col md={12}>
            <div ref={blogRef}>
              <LawyerBlogPosts lawBlogPost={lawyer.lawBlogPost} gradeClass={gradeClass} />
            </div>
          </Col>
        </Row>
      </Container>
  
      {/* Modal for Contact */}
      <LawyerContact
        show={showModal}
        handleClose={handleCloseModal}
        lawyer={lawyer}
      />
    </>
  );
  
  
}

export default LawyerDetailPage;
