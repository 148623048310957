import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FooterUI() {
  return (
    <footer className="bg-light mt-auto">
      <Container>
        <Row className="align-items-center flex-column-reverse flex-md-row">
          <Col className="text-center text-md-start">
            <p className="m-0 py-3 footer-p">
              © 2024 upLawyer. Με επιφύλαξη παντός νόμιμου δικαιώματος.
            </p>
          </Col>
          <Col className="text-center text-md-end">
            <a href="/policy-privacy" className="me-3 text-decoration-none">
              Πολιτική Απορρήτου
            </a>
            <a href="/policy-terms" className="me-3 text-decoration-none">
              Όροι και Προϋποθέσεις
            </a>
            <a href="/contact" className="text-decoration-none">
              Επικοινωνία
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterUI;
