import React, { useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './HomePros.css';

// Register ScrollTrigger plugin with GSAP
gsap.registerPlugin(ScrollTrigger);

const HomePros = () => {
  const prosSectionRef = useRef(null); // Reference for the whole section
  const prosColumnsRef = useRef([]);   // Array to store references for each column

  useEffect(() => {
    const isMobile = window.innerWidth < 768; // Set mobile device check

    if (!isMobile) {
      console.log("Setting up GSAP ScrollTrigger");

      // Set up ScrollTrigger to animate only when section is visible
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: prosSectionRef.current,  // The section to observe
          start: 'top 90%',                 // Adjusting when the trigger starts (70% down the viewport)
          end: 'bottom 100%',                // Optional: End point (animation will reset if scrolled up past this point)
          toggleActions: 'play none none none', // Play the animation when entering the viewport
          onEnter: () => console.log('ScrollTrigger1 triggered'), // Log when ScrollTrigger is triggered
        },
      });

      timeline
        .fromTo(prosColumnsRef.current[0], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 })
        .fromTo(prosColumnsRef.current[1], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 }, '-=0.4')
        .fromTo(prosColumnsRef.current[2], { opacity: 0, y: 50 }, { opacity: 1, y: 0, duration: 1 }, '-=0.4');

      console.log("GSAP timeline1 initialized");
    }
  }, []);

  return (
    <div className="home-pros-section">
      <Container>
        <Row className="justify-content-center text-center">
          <h1 className="pb-4">Γιατί το upLawyer ?</h1>
          <p className="mb-4 pb-4 hero-p">
            Το upLawyer αποτελεί μια κορυφαία επιλογή αναζήτησης δικηγόρου και νομικών υπηρεσιών.
          </p>
        </Row>
      </Container>
      <Container className="my-1 pt-0 pt-md-3">
        <Row className="text-center" ref={prosSectionRef}>
          <Col md={4} className="mb-5 mb-md-0 pros-column first-pro" ref={el => prosColumnsRef.current[0] = el}>
            <div className="pros-content-wrapper">
              <h3>Τεχνητή Νοημοσύνη</h3>
              <p className="pt-2 pros-p">για την ευφυή αναζήτηση ειδικότητας δικηγόρου</p>
            </div>
          </Col>
          <Col md={4} className="mb-5 mb-md-0 pros-column second-pro" ref={el => prosColumnsRef.current[1] = el}>
            <div className="pros-content-wrapper">
              <h3>Επικοινωνία</h3>
              <p className="pt-2 pros-p">για την άμεση αποστολή μηνύματος σε δικηγόρο</p>
            </div>
          </Col>
          <Col md={4} className="pros-column" ref={el => prosColumnsRef.current[2] = el}>
            <div className="pros-content-wrapper">
              <h3>Μηδενικό Κόστος</h3>
              <p className="pt-2 pros-p">για την δωρέαν εύρεση του κατάλληλου δικηγόρου</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePros;
