import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { FaRobot } from "react-icons/fa";

function NavBarUI() {
  const [navbarBlur, setNavbarBlur] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setNavbarBlur(true);
      } else {
        setNavbarBlur(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isNavbarOpen) {
      document.body.classList.add('nav-open');
    } else {
      document.body.classList.remove('nav-open');
    }

    return () => {
      document.body.classList.remove('nav-open');
    };
  }, [isNavbarOpen]);

  const handleToggle = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <>
      <Navbar
        expand="lg"
        className={`fixed-top ${navbarBlur || isNavbarOpen ? "navbar-blur" : "navbar-transparent"}`}
        expanded={isNavbarOpen}
      >
        <Container className="d-flex justify-content-between align-items-center">
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={handleToggle}
            className="order-lg-last order-1"
          />
          <Navbar.Brand as={Link} to="/" className="order-lg-first order-2">
            <strong>upLawyer</strong>
          </Navbar.Brand>

          <Navbar.Collapse
            id="basic-navbar-nav"
            className={`order-3 ${isNavbarOpen ? 'navbar-collapse-mobile-fullscreen' : ''}`}
          >
            <Nav className="w-100 d-flex justify-content-center justify-content-lg-center">
              <Nav.Link as={Link} to="/dikigoroi" className="me-3">
                Δικηγόροι
              </Nav.Link>
              <Nav.Link as={Link} to="/dikigorikoi-syllogoi" className="me-3">
                Δικηγορικοί Σύλλογοι
              </Nav.Link>
              <Nav.Link as={Link} to="/blog" className="me-3">
                Αρθρογραφία
              </Nav.Link>
            </Nav>

            <Nav className="ms-lg-auto d-flex align-items-center">
              <a
                href="/ai-assistant"
                className="btn btn-outline-uplawyer nav-btn"
                style={{ padding: "0.5rem 20px", whiteSpace: "nowrap" }}
              >
                <FaRobot className="mb-1 me-2" /> AI βοηθός
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className={`main-content ${isNavbarOpen ? "blur-background" : ""}`}></div>
    </>
  );
}

export default NavBarUI;
