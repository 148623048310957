// src/components/Home/Home.js
import React from 'react';

/* Import HomeHero section */
import HomeHero from './HomeHero';
/* Import HomeTestimonals section */
import HomeTestimonals from './HomeTestimonals';
/* Import HomeRegister section */
import HomeRegister from './HomeRegister';
/* Import HomeLawyers section */
import HomeLawyers from './HomeLawyers';
/* Import HomeAI section */
import HomePros from './HomePros';

function Home() {
  return (
    <>
      <HomeHero />
      <HomeTestimonals />
      <HomeLawyers />
      <HomePros />
      <HomeRegister />
    </>
  );
}

export default Home;
