import React, { useEffect, useState } from 'react';
import fetchGraphQLData from '../apiCalls/fetchGraphQLData';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/* Import the reusable BlogPost component */
import BlogPost from './LawBlogCard';

function LawBlogPosts() {
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = async () => {
      const blogPostsQuery = `
      {
        lawBlogPosts(first: 100) {
          id
          lawPostTitle
          lawPostDate
          lawPostBody {
            markdown
          }
          lawyer {
            firstName
            lastName
          }
        }
      }
      `;

      try {
        const blogPostsData = await fetchGraphQLData(blogPostsQuery);

        if (blogPostsData && blogPostsData.lawBlogPosts) {
          setBlogPosts(blogPostsData.lawBlogPosts);
        } else {
          console.error('Error: Data structure is not as expected:', blogPostsData);
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <>
    <Container className="my-3 pt-3">
    <Row>
      <h1 className="my-2">Αρθρογραφία</h1>
      <h5 className="mb-4 mt-3">
        Επιλεγμένη αρθρογραφία και απόψεις από δικηγόρους του <span className="navbar-brand fw-medium">upLawyer</span>.
      </h5>
    </Row>
  </Container>

    <Container className="my-3">

      <Row>
        {blogPosts.map((post) => (
          <Col key={post.id} sm={12} md={6} lg={4}>
            <BlogPost
              title={post.lawPostTitle}
              date={post.lawPostDate}
              author={`${post.lawyer.firstName} ${post.lawyer.lastName}`}
              excerpt={post.lawPostBody.markdown.slice(0, 100)}
            />
          </Col>
        ))}
      </Row>
    </Container>
    </>
  );
}

export default LawBlogPosts;
