// src/App.js
import React from "react";

/* Import UI components from react-bootstrap library */
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

function PolicyPrivacy() {
  return (
    <>
      <Container className="my-3 pt-3">
        <Row>
          <h1 className="my-2">Όροι και Προϋποθέσεις</h1>
          <h5 className="mb-4 mt-3">
            Όροι και προϋποθέσεις χρήσης των υπηρεσιών του{" "}
            <span className="navbar-brand fw-medium">upLawyer</span>.
          </h5>
        </Row>
      </Container>

      <Container className="my-3">
        <Row className="policy-p">
          <section>
            <p>
              O σεβασμός και η εξασφάλιση των προσωπικών δεδομένων και του
              απορρήτου των χρηστών της πλατφόρμας{" "}
              <span className="fw-medium">upLawyer.gr </span>
              αποτελεί βασική προτεραιότητα, η οποία διέπεται από την παρούσα
              Πολιτική Προστασίας Προσωπικών Δεδομένων (ΠΠΠΔ). Η ΠΠΠΔ
              απευθύνεται σε κάθε τελικό χρήστη που χρησιμοποιεί την πλατφόρμα
              του upLawyer, είτε με σχέση απλού χρήστη είτε με σχέση
              προβαλλόμενου συνεργάτη. Συμμορφούμενοι και στοιχιζόμενοι με το
              κανονιστικό πλαίσιο της ΕΕ για την προστασία δεδομένων, η εκ
              μέρους μας επεξεργασία δεδομένων και απορρήτου γίνεται με απόλυτα
              θεμιτό και σύννομο τρόπο, για έναν συγκεκριμένο και νόμιμο σκοπό
              και καλύπτει μόνο τα δεδομένα που είναι αναγκαία για την επίτευξη
              αυτού του σκοπού. Προκειμένου να επεξεργαστούμε τα προσωπικά
              δεδομένα διασφαλίζουμε ότι έχουμε τη συγκατάθεση του συγκεκριμένου
              υποκειμένου των δεδομένων και λαμβάνουμε ΜΟΝΟ τα προσωπικά
              δεδομένα που μας είναι απαραίτητα για να τηρήσουμε τις υποχρεώσεις
              μας έναντι του υποκειμένου των δεδομένων. Η παρούσα ΠΠΠΔ παρέχει
              αναλυτικές πληροφορίες σχετικά με το είδος των προσωπικών
              δεδομένων που συλλέγουμε, και εξηγεί τον τρόπο με τον οποίο η
              εταιρεία μας επεξεργάζεται τα προσωπικά σας δεδομένα, καθώς και το
              πώς μπορείτε να διαχειριστείτε και να ελέγχετε τη χρήση των
              πληροφοριών σας, σύμφωνα με τις διατάξεις του ελληνικού δικαίου
              και της ευρωπαϊκής νομοθεσίας περί προστασίας των δεδομένων
              προσωπικού χαρακτήρα, συμπεριλαμβανομένου του Γενικού Κανονισμού
              για την Προστασία Δεδομένων Προσωπικού Χαρακτήρα (2016/679/ΕΕ),
              που είναι ευρέως γνωστός ως «ΓΚΠΔ» (GDPR), ο οποίος τέθηκε σε
              εφαρμογή την 25/5/2018.
            </p>
          </section>

          <section>
            <h3>
              <span className="fw-medium">
                Ποιος είναι ο Υπεύθυνος Επεξεργασίας των Προσωπικών Δεδομένων
                σας;
              </span>
            </h3>

            <p>
              Ο Υπεύθυνος Επεξεργασίας για τη συλλογή, αποθήκευση, επεξεργασία
              και χρήση των προσωπικών σας δεδομένων είναι το upLawyer
              (privacy@uplawyer.gr). Στόχος αυτής της Δήλωσης Πολιτικής
              Προστασίας Προσωπικών Δεδομένων είναι να σας ενημερώσουμε και να
              σας παρέχουμε όλες τις απαραίτητες πληροφορίες σχετικά με τη
              συλλογή, αποθήκευση, χρήση και επεξεργασία των προσωπικών σας
              δεδομένων, καθώς και τα δικαιώματά σας. Η πλατφόρμα μας, σε πλήρη
              συμμόρφωση με την ισχύουσα νομοθεσία, έχει λάβει όλα τα απαραίτητα
              μέτρα (τεχνικά, οργανωτικά κ.λπ.) για τη νόμιμη επεξεργασία και
              ασφαλή φύλαξη των προσωπικών σας δεδομένων, προκειμένου να
              διασφαλίσει την προστασία τους από τον κίνδυνο απώλειας, διαρροής,
              αλλοίωσης, μη εξουσιοδοτημένης πρόσβασης ή οποιασδήποτε άλλης
              αθέμιτης ή παράνομης επεξεργασίας.
            </p>
          </section>

          <section>
            <h3>
              <span className="fw-medium">
                Τι είδους προσωπικά δεδομένα επεξεργαζόμαστε και για ποιους
                σκοπούς;
              </span>
            </h3>

            <p>
              Τα προσωπικά δεδομένα αφορούν πληροφορίες που μπορούν να σας
              ταυτοποιήσουν, όπως το όνομα, η διεύθυνση ηλεκτρονικού
              ταχυδρομείου, η ταχυδρομική διεύθυνση ή το τηλέφωνό σας. Η
              Εταιρεία μας επεξεργάζεται τα ακόλουθα προσωπικά δεδομένα για τους
              σκοπούς που αναφέρονται παρακάτω:
            </p>

            <p>
              Προσωπικά δεδομένα που συλλέγουμε και επεξεργαζόμαστε για
              λογαριασμό σας περιλαμβάνουν:
            </p>

            <ul>
              <li>
                Κατά τη συμπλήρωση της φόρμας επικοινωνίας για να έρθετε σε
                επαφή με τους δικηγόρους μας: Όνομα, Επώνυμο, διεύθυνση
                ηλεκτρονικού ταχυδρομείου (email) και τηλέφωνο επικοινωνίας
                (σταθερό ή/και κινητό).
              </li>
              <li>
                Κατά την εγγραφή σας στον ιστότοπό μας, για να προβληθείτε στην
                πλατφόρμα ως δικηγόροι προσφέροντας νομικές υπηρεσίες σε
                ενδιαφερόμενους ή για να λάβετε άλλες υπηρεσίες μας: Όνομα,
                Επώνυμο, φωτογραφία, διεύθυνση email, τηλέφωνο επικοινωνίας
                (σταθερό ή/και κινητό), τίτλος πτυχίου και το Ίδρυμα
                αποφοίτησης, τομέας ενδιαφέροντος για την εξεύρεση εργασίας,
                καθώς και άλλα στοιχεία που παρέχετε με την υποβολή του
                βιογραφικού σας. Σε καμία περίπτωση δεν συλλέγουμε δεδομένα που
                θεωρούνται ευαίσθητα σύμφωνα με τον Νόμο και τις σχετικές
                αποφάσεις.
              </li>
            </ul>

            <p>
              <strong>Σκοπός επεξεργασίας:</strong> Συλλέγουμε, αποθηκεύουμε και
              επεξεργαζόμαστε μόνο τα ελάχιστα και απολύτως απαραίτητα προσωπικά
              δεδομένα για την επίτευξη του κάθε συγκεκριμένου σκοπού
              επεξεργασίας.
            </p>

            <p>
              <strong>Τρόπος συλλογής των προσωπικών σας δεδομένων:</strong> Τα
              προσωπικά σας δεδομένα συλλέγονται απευθείας από εσάς όταν τα
              καταχωρείτε διαδικτυακά στην ιστοσελίδα μας ή κατά την εγγραφή σας
              στο newsletter μας.
            </p>
          </section>

          <section>
            <h3>
              <span className="fw-medium">
                Σε ποιους κοινοποιούμε τα προσωπικά σας δεδομένα;
              </span>
            </h3>
            <p>
              Δεν διαβιβάζουμε τα προσωπικά σας δεδομένα σε τρίτους, παρά μόνο
              σε αυτούς με τους οποίους συνεργαζόμαστε και που είναι απαραίτητοι
              ή διευκολύνουν την παροχή των υπηρεσιών μας προς εσάς, αλλά
              πάντοτε υπό προϋποθέσεις που εξασφαλίζουν πλήρως ότι τα προσωπικά
              σας στοιχεία δεν υφίστανται οποιαδήποτε παράνομη επεξεργασία,
              δηλαδή άλλη από το σκοπό της διαβίβασης σύμφωνα με τα παραπάνω. Σε
              κάθε περίπτωση η Εταιρεία μας εγγυάται ότι δεν θα προβεί σε
              διαβίβαση, κοινοποίηση, διάθεση κ.λπ. των προσωπικών σας δεδομένων
              σε τρίτους, χωρίς τη συγκατάθεσή σας, για οποιοδήποτε σκοπό ή
              χρήση. Ωστόσο, διατηρούμε το δικαίωμα να κοινοποιήσουμε
              πληροφορίες που σας αφορούν, εάν η νομοθεσία μας υποχρεώνει
              σχετικά ή εάν η κοινοποίηση αυτή απαιτείται από τους αρμόδιους
              κυβερνητικούς φορείς, από διοικητικές αρχές ή όργανα επιβολής του
              νόμου.
            </p>
            <p>
              Σημειώνουμε πως απαγορεύεται ρητά η πρόσβαση προσώπων στα
              προσωπικά σας δεδομένα χωρίς εξουσιοδότηση, συμπεριλαμβανομένων
              των εργαζομένων μας. Σε περίπτωση που είναι απαραίτητη η διαβίβαση
              των προσωπικών σας δεδομένων σε τρίτη χώρα εκτός του Ευρωπαϊκού
              Οικονομικού Χώρου (ΕΟΧ), θα διασφαλίζουμε πάντοτε ότι παρέχεται
              επαρκές επίπεδο προστασίας για την επεξεργασία τους, δεσμεύοντας
              συμβατικά τον αντισυμβαλλόμενό μας ή τον συνεργάτη μας στον οποίο
              κοινοποιούμε τα προσωπικά σας δεδομένα, ώστε να εξασφαλίζεται το
              ίδιο επίπεδο προστασίας των προσωπικών σας δεδομένων, με αυτό που
              παρέχεται στην Ελλάδα.
            </p>
          </section>

          <section>
            <h3>
              <span className="fw-medium">
                Ποια είναι τα δικαιώματά σας σχετικά με τα προσωπικά σας
                δεδομένα;
              </span>
            </h3>

            <p>
              Σύμφωνα με τον Γενικό Κανονισμό για την Προστασία Δεδομένων (GDPR)
              της Ευρωπαϊκής Ένωσης, έχετε τα ακόλουθα δικαιώματα:
            </p>

            <ul>
              <li>
                <strong>Δικαίωμα πρόσβασης:</strong> Έχετε το δικαίωμα να
                ζητήσετε πληροφορίες για τα προσωπικά σας δεδομένα που
                επεξεργαζόμαστε, καθώς και για την επεξεργασία τους.
              </li>
              <li>
                <strong>Δικαίωμα διόρθωσης:</strong> Μπορείτε να ζητήσετε τη
                διόρθωση των προσωπικών σας δεδομένων, εφόσον είναι ανακριβή ή
                εάν επιθυμείτε να τα επικαιροποιήσετε.
              </li>
              <li>
                <strong>Δικαίωμα εναντίωσης:</strong> Έχετε το δικαίωμα να
                αντιταχθείτε στην επεξεργασία των προσωπικών σας δεδομένων όταν
                αυτή βασίζεται σε έννομο συμφέρον, συμπεριλαμβανομένου του
                δικαιώματός σας να αρνηθείτε την επεξεργασία για προωθητικούς
                σκοπούς.
              </li>
              <li>
                <strong>Δικαίωμα περιορισμού της επεξεργασίας:</strong> Μπορείτε
                να ζητήσετε τον περιορισμό της επεξεργασίας των δεδομένων σας,
                εφόσον αμφισβητείτε την ακρίβειά τους, αντιτίθεστε στην
                επεξεργασία τους ή συντρέχει άλλος λόγος που προβλέπεται από τη
                σχετική ελληνική ή ευρωπαϊκή νομοθεσία.
              </li>
              <li>
                <strong>Δικαίωμα φορητότητας:</strong> Έχετε τη δυνατότητα να
                λάβετε τα προσωπικά σας δεδομένα και να τα μεταφέρετε οπουδήποτε
                αλλού, εφόσον η επεξεργασία τους βασίζεται στη συγκατάθεσή σας
                και γίνεται με αυτοματοποιημένα μέσα, λαμβάνοντας υπόψη τις
                υποχρεώσεις μας να διατηρούμε τα δεδομένα για λόγους δημοσίου
                συμφέροντος.
              </li>
              <li>
                <strong>Δικαίωμα διαγραφής ("δικαίωμα στη λήθη"):</strong>{" "}
                Μπορείτε να ζητήσετε τη διαγραφή των προσωπικών σας δεδομένων
                χωρίς αδικαιολόγητη καθυστέρηση, εφόσον πληρούνται οι
                προϋποθέσεις της ελληνικής και ευρωπαϊκής νομοθεσίας περί
                προστασίας δεδομένων.
              </li>
              <li>
                <strong>Δικαίωμα ανάκλησης συγκατάθεσης:</strong> Όταν η
                επεξεργασία βασίζεται στη συγκατάθεσή σας, έχετε το δικαίωμα να
                την ανακαλέσετε οποτεδήποτε, χωρίς να επηρεαστεί η νομιμότητα
                της επεξεργασίας που πραγματοποιήθηκε πριν από την ανάκληση.
              </li>
              <li>
                <strong>Δικαίωμα ενημέρωσης για περιστατικά παραβίασης:</strong>{" "}
                Έχετε δικαίωμα να ενημερωθείτε σε περίπτωση παραβίασης των
                προσωπικών σας δεδομένων, εάν κρίνεται αναγκαίο.
              </li>
            </ul>
          </section>

          <section>
            <h2>Διεκπεραίωση αιτήσεων</h2>
            <p>
              Όταν λαμβάνουμε αίτημα άσκησης δικαιωμάτων από υποκείμενο
              δεδομένων, υποχρεούμαστε να απαντήσουμε χωρίς καθυστέρηση και το
              αργότερο εντός ενός μήνα. Ο χρόνος απάντησης μπορεί να παραταθεί
              κατά δύο μήνες σε περίπτωση πολύπλοκων ή πολλαπλών αιτημάτων, υπό
              την προϋπόθεση ότι θα ενημερωθείτε σχετικά με την παράταση. Η
              διεκπεραίωση των αιτήσεων γίνεται δωρεάν. Σε περίπτωση απόρριψης
              του αιτήματος, ενημερώνουμε το υποκείμενο των δεδομένων για τους
              λόγους απόρριψης και το δικαίωμά του να υποβάλει καταγγελία στην
              Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα.
            </p>
          </section>

          <section>
            <h2>Ασφάλεια δεδομένων</h2>
            <p>
              Έχουμε εφαρμόσει τεχνικά και οργανωτικά μέτρα για την ασφαλή
              συλλογή, επεξεργασία και προστασία των προσωπικών σας δεδομένων
              από απώλεια, αλλοίωση ή μη εξουσιοδοτημένη πρόσβαση.
            </p>
          </section>

          <section>
            <h2>Τροποποίηση Πολιτικής Προστασίας Δεδομένων</h2>
            <p>
              Η εταιρεία μας διατηρεί το δικαίωμα να τροποποιεί ή να ενημερώνει
              αυτή την Πολιτική σύμφωνα με τις αλλαγές στη νομοθεσία ή τη
              νομολογία, χωρίς προηγούμενη ενημέρωσή σας. Συνιστούμε να
              διαβάζετε την Πολιτική Προστασίας Δεδομένων πριν από τη χρήση της
              ιστοσελίδας και των υπηρεσιών μας, ώστε να είστε ενήμεροι για την
              πιο πρόσφατη έκδοση της Πολιτικής.
            </p>
          </section>
        </Row>
      </Container>
    </>
  );
}

export default PolicyPrivacy;
